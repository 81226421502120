import React from 'react';
import "../bannerStyle.scss";
import redCornerIcon from  "../../assets/red-corner.svg";

const GeneralBanner = (props) => {
    return (
            <div id="info-banner" className="banner_container">
                <img alt="red corner" className="red-corner" src={redCornerIcon} />
                <h4 id="info-banner-header" className="header">{props.headerText}</h4>
                <div className="content"> 
                    <p id="info-banner-text" className="info-text">
                        {props.infoText}
                    </p>
                    <div className="clear-both"></div>
                </div>
            </div>
    )
};
export default GeneralBanner;



