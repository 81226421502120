import React from 'react';
import {  Route, Switch } from 'react-router-dom';
import './app.scss'
import CheckoutScene from './scenes/checkout';
import SigningCompleteScene from './scenes/signingComplete';
import TermsView from './scenes/terms/termsView';
const App = (props) => {

    return (
      <div className="app-container">
        <Switch>
          <Route path="/checkout" component={CheckoutScene} />
          <Route path="/signing-complete" component={SigningCompleteScene} />
          <Route path="/terms/:id/:action" render={(props) => <TermsView {...props} />} />
        </Switch>
      </div>
     );
    }
export default App;