import React from 'react';
import "./styles.scss";
import BannerBase from "../BannerBase";

const SignatoryNotFoundBanner = (props) => {
    return (
        <BannerBase header = {'Tyvärr kan vi inte erbjuda ert företag finansiering'} handleCancel = {e => props.handleCancel(e)} >
            Din varukorg kräver signatur av ägare/företrädare.
            <br/> Företrädare kan inte fastställas vänligen välj ett annat betalalternativ.
        </BannerBase>
    )
};
export default SignatoryNotFoundBanner;



