import React from 'react';
import {ReactComponent as Pencil } from '../../assets/pencil.svg';
import {ReactComponent as Undo } from '../../assets/undo.svg';
import './style.scss';
const ChangeAddressButton = (props) => {
    
    return (
        <React.Fragment>
            {
                (["ab","e"].filter(f=>props.companyLegalForm ===f).length!==0)?
                    <div>
                        {
                            props.addressIsInEditMode ? 
                            <button title="Ångra adressändring"
                            onClick={props.onRedoAddressChangeClick}
                            className="change-address-button undo"><Undo />Ångra...</button>
                            :
                            <button
                            onClick={props.onChangeAddressButtonClick}
                            className="change-address-button"><Pencil />Ändra...</button>

                        }
                    </div>
                    : null
            }
        </React.Fragment>


    );
}
export default ChangeAddressButton;
