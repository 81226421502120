import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import OrganizationNumberLookup from "../../components/OrganizationNumberLookup";
import {
    checkSignatory,
    createBillOfPurchase,
    getCheckout,
    putCustomer,
    putInvoiceInformation
} from "../../services/api.js";
import TestModeBanner from '../../components/TestModeBanner';
import './style.scss';
import '../../columns.scss';
import CustomerAddress from '../../components/CustomerAddress';
import {Shout} from '../../services/shout';
import OnResize from './resizeHandler';
import GeneralBanner from '../../components/GeneralBanner';
import ErrorView from '../../components/ErrorView';
import FinancingInfo from '../../components/FinancingInfo';
import {getIdFromQueryString} from '../../services/utils'
import {validateName, validatePhone} from "../../components/CustomerAddress/recipientValidation";
import emailValidator from "email-validator";
import TermsScene from '../terms';
import Signatory from '../../components/Signatory';
import SignButton from '../../components/SignButton';
import DisclaimerInfo from "../../components/DisclaimerInfo";
import {getCookie, setCookie} from "../../services/cookies.js";
import SignatoryNotFoundBanner from "../../components/SignatoryNotFoundBanner";
import postMessage from "../../services/postMessage";
import NonSelectableSignatory from '../../components/Signatory/nonSelectableSignatory';

const CheckoutScene = () => {
    let shout = new Shout();
    const testModeOrgNo = "6806249022";
    const [checkoutModel, setCheckoutModel] = useState(null);
    const [purchaserEmailIsValid, setPurchaserEmailIsValid] = useState(null);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [selectedBillingAddressId, setSelectedBillingAddressId] = useState(null);
    const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] = useState(null);
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
    const [billingDetails, setBillingDetails] = useState(null);
    const [recipient, setRecipient] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [generalErrorBanner, setGeneralErrorBanner] = useState({show: false, header: '', info: ''});
    const [isFetchingSignUrl, setIsFetchingSignUrl] = useState(false);
    const [signatoryIsValid, setSignatoryIsValid] = useState(false);
    const [signatoryIsSelectable, setSignatoryIsSelectable] = useState(false);
    const [fetchSignUrlError, setFetchSignUrlError] = useState(null);
    const [signatoryPersonNumber, setSignatoryPersonNumber] = useState(null);
    const [showSignatoryErrorView, setShowSignatoryErrorView] = useState(false);
    const [deliveryAddressIsValid, setDeliveryAddressIsValid] = useState(true);
    const [signatoryCheckedForAdressChange, setSignatoryCheckedForAdressChange] = useState(false);

    useEffect(() => {
        let checkoutId = getIdFromQueryString();
        if (!checkoutId) {
            console.log("missing querystring:id");
            setHasError(true);
            return;
        }
        const fetchCheckout = () => {
            return getCheckout(checkoutId).then(res => {
                setCheckoutModelAndCheckForCookies(res);
                if (res.state === "Completed") {
                    setRedirectUrl(`/signing-complete?id=${res.checkout_id}`);
                }
            }).catch(err => {
                console.log("Error fetching checkout", err);
                setHasError(true);
            });
        }
        fetchCheckout();
    }, [shout]);


    const setTestModeOrgNo = () => {
        shout.shoutOut("activateTestOrgnumber", {testOrgNumber: testModeOrgNo});
    }

    const _handleCancel = () => {
        postMessage.checkoutCancel(checkoutModel.order_references);
    }

    const reset = () => {
        setCustomerLoading(true);
        setSelectedBillingAddressId(null);
        setSignatoryIsValid(false);
        setSelectedDeliveryAddressId(null);
        setGeneralErrorBanner({show: false, header: '', info: ''});
        setRecipient(null);
        setBillingDetails(null);
        setSelectedDeliveryAddress(null);
    }

    const onSubmitOrgNo = (e, orgNumber) => {
        e.preventDefault();
        reset();
        checkSignatory(checkoutModel.checkout_id, orgNumber).then(resp => {
                //setSignatoryIsValid(!resp.is_signatory_selectable);
                setSignatoryIsSelectable(resp.is_signatory_selectable);
            }
        ).then(_ => {
            putCustomer(checkoutModel.checkout_id, orgNumber).then(res => {
                setCustomer(res);
                let selectedDefaultAddressId = res.addresses.filter(x => x.selected)[0].id;
                setSelectedBillingAddressId(selectedDefaultAddressId);
                setSelectedDeliveryAddressId(selectedDefaultAddressId);

                let _billingDetails = checkoutModel.billing_details === null ? {
                    billing_reference: '',
                    billing_tag: ''
                } : checkoutModel.billing_details;
                let isBillingDetailsValid = emailValidator.validate(checkoutModel.purchaser_email);
                setBillingDetails({
                    billing_reference: _billingDetails.billing_reference,
                    billing_tag: _billingDetails.billing_tag,
                    purchaser_email: checkoutModel.purchaser_email,
                    purchaser_name: checkoutModel.purchaser_name,
                    isValid: isBillingDetailsValid
                });
                let isRecipientValid = validateName(checkoutModel.recipient_name) && validatePhone(checkoutModel.recipient_phone);
                setRecipient({
                    recipient_name: checkoutModel.recipient_name,
                    recipient_phone: checkoutModel.recipient_phone,
                    isValid: isRecipientValid
                });
                setCustomerLoading(false);
            }).catch(err => {
                console.log("some error", err.developer_message !== undefined ? err.developer_message : err);
                setCustomerLoading(false);
                activateErrorBanner("Oj, nu gick något fel", "Vi kunde tyvärr inte hämta information om det angivna bolaget.");
            })
        }).catch(err => {
            console.log("some error", err.developer_message !== undefined ? err.developer_message : err);
            setCustomerLoading(false);
            setShowSignatoryErrorView(true);
        })

    }

    const submitInvoiceInformation = (callback) => {
        if (!deliveryAddressIsValid) {
            return;
        }
        let request = {
            billing_details: {
                billing_reference: billingDetails.billing_reference,
                billing_tag: billingDetails.billing_tag,
                purchaser_email: billingDetails.purchaser_email,
                purchaser_name: billingDetails.purchaser_name
            },
            recipient: {
                name: recipient.recipient_name,
                phone_number: recipient.recipient_phone,
            },
            selected_billing_address_id: selectedBillingAddressId,
            selected_delivery_address_id: selectedDeliveryAddressId,
            delivery_street_address: selectedDeliveryAddress?.street_address,
            delivery_postal_code: selectedDeliveryAddress?.postal_code,
            delivery_city: selectedDeliveryAddress?.city,

        };

        putInvoiceInformation(checkoutModel.checkout_id, request).then(res => {
            callback();
        }).catch(err => {
            console.log("some error", err.developer_message);
            activateErrorBanner("Oj, nu gick något fel", "Något oväntat hände. Var god försök igen!");
        })

    }

    const fetchSignUrl = () => {
        setCookies();
        submitInvoiceInformation(() => {
            let request = {
                signer_personal_number: signatoryPersonNumber,
            }
            setIsFetchingSignUrl(true);
            setFetchSignUrlError(null);

            return createBillOfPurchase(checkoutModel.checkout_id, request).then(res => {
                window.location.href = res.sign_url;
            }).catch(ex => {
                setIsFetchingSignUrl(false);
                setFetchSignUrlError(ex);
            });
        });
    }

    const setCookies = () => {
        setCookie('customer_organization_number', customer.organization_number);
        setCookie('purchaser_email', billingDetails.purchaser_email);
    }

    const setCheckoutModelAndCheckForCookies = (checkout) => {
        if (checkout !== undefined) {
            if (checkout.customer_organization_number === undefined || checkout.customer_organization_number === "" || checkout.customer_organization_number === null) {
                let orgnr = getCookie('customer_organization_number');
                checkout.customer_organization_number = orgnr ? orgnr : ""
            }

            if (checkout.purchaser_email === undefined || checkout.purchaser_email === "" || checkout.purchaser_email === null) {
                let email = getCookie('purchaser_email');
                checkout.purchaser_email = email ? email : ""
            }
        }
        setCheckoutModel(checkout);
    }


    const deliveryAddressChanged = (deliveryAddress) => {
        //take care of whole address, when edited
        setSelectedDeliveryAddress(deliveryAddress);
    }


    const billingDetailsChanged = (objDetails) => {
        setBillingDetails(objDetails);
    }

    const purchaserEmailChanged = (email, isValid) => {
        let cm = {...checkoutModel};
        cm.purchaser_email = email;
        setCheckoutModel(cm);
        setPurchaserEmailIsValid(isValid);
    }

    const signatoryPersonalNumberChanged = (personalNumber, isValid) => {
        setSignatoryPersonNumber(personalNumber);
        setSignatoryIsValid(isValid);
    }

    const recipientChanged = (objDetails) => {
        setRecipient(objDetails);
    }

    const activateErrorBanner = (header, info) => {
        setGeneralErrorBanner({show: true, header: header, info: info});
    }
    const isSignButtonDisabled = () => { //maybe use this or do something cooler.
        if (isFetchingSignUrl)
            return true;
        if (signatoryIsSelectable && !signatoryIsValid)
            return true;
        if (!recipient.isValid)
            return true;
        if (!deliveryAddressIsValid)
            return true;

        return false;
    }
    return (
        hasError ? <ErrorView/> :
            checkoutModel == null ? <div/> :
                redirectUrl !== null ? <Redirect to={redirectUrl}/> :
                    <div className="main-container">
                        <OnResize/>
                        {
                            checkoutModel.test_mode &&
                            <TestModeBanner onSetTestModeOrgNo={setTestModeOrgNo}/>
                        }
                        <DisclaimerInfo/>

                        {(recipient === null || billingDetails === null) &&
                            <React.Fragment>
                                <OrganizationNumberLookup
                                    customerLoading={customerLoading}
                                    onSubmitOrgNo={onSubmitOrgNo}
                                    organizationNumber={checkoutModel.customer_organization_number}
                                    purchaser_email={checkoutModel.purchaser_email}
                                    purchaserEmailChanged={purchaserEmailChanged}
                                />
                                {showSignatoryErrorView ?
                                    <SignatoryNotFoundBanner handleCancel={e => _handleCancel(e)}/> : null}
                                <FinancingInfo totalCost={checkoutModel.total_price_ex_vat}/>
                                <TermsScene
                                    customerIsValid={purchaserEmailIsValid}
                                    checkoutModel={checkoutModel}/>
                            </React.Fragment>
                        }

                        {generalErrorBanner.show === false ?
                            <div>
                                {
                                    (recipient !== null && billingDetails !== null) &&
                                    <React.Fragment>
                                        <CustomerAddress
                                            checkoutModel={checkoutModel}
                                            setDeliveryAddressIsValid={setDeliveryAddressIsValid}
                                            deliveryAddressChanged={deliveryAddressChanged}
                                            billingDetailsChanged={billingDetailsChanged}
                                            setSignatoryCheckedForAdressChange={setSignatoryCheckedForAdressChange}
                                            signatoryIsSelectable={signatoryIsSelectable}
                                            setSignatoryIsSelectable={setSignatoryIsSelectable}
                                            billingDetails={billingDetails}
                                            recipientChanged={recipientChanged}
                                            recipient={recipient}
                                            customer={customer}>
                                            {
                                                signatoryIsSelectable ?
                                                    <Signatory
                                                        customer={customer}
                                                        signatoryPersonalNumberChanged={signatoryPersonalNumberChanged}/>
                                                    :
                                                    <NonSelectableSignatory/>

                                            }

                                        </CustomerAddress>
                                        <FinancingInfo
                                            signatoryCheckedForAdressChange={signatoryCheckedForAdressChange}
                                            signatoryIsSelectable={signatoryIsSelectable}
                                            customer={customer}
                                            totalCost={checkoutModel.total_price_ex_vat}/>

                                        <SignButton
                                            generateSignUrl={fetchSignUrl}
                                            loading={isFetchingSignUrl}
                                            disabled={isFetchingSignUrl || (signatoryIsSelectable && !signatoryIsValid) || !recipient.isValid || !deliveryAddressIsValid}/>
                                        <TermsScene
                                            customerIsValid={purchaserEmailIsValid}
                                            checkoutModel={checkoutModel}/>
                                        {
                                            fetchSignUrlError !== null &&
                                            <div id="signUrlError" className="error-message">Hoppsan nu gick något fel.
                                                Var god försök igen!</div>
                                        }

                                    </React.Fragment>
                                }

                            </div>
                            : <GeneralBanner
                                headerText={generalErrorBanner.header}
                                infoText={generalErrorBanner.info}/>
                        }
                    </div>
    );
}
export default CheckoutScene;