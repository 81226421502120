import React, {useState, useEffect} from "react";
import "./styles.scss";

export default function Input(props) {
    const [labelText, setLabelText] = useState('');
    let {id, errorMessage, label, type, className, ref, readOnly, value, ...rest} = props;

    useEffect(() => {
        if (value !== undefined && value!==null && value.length > 0) {
            setLabelText(label);
        }

    }, [value]);


    const onChangeValue = (value) => {
        if (value !== undefined && value.length > 0) {
            setLabelText(label);
        } else {
            setLabelText('');
        }
    }

    return (
        <div className={ `input-container ${className}`}>
            <label title={errorMessage && errorMessage} >
            <span className="take-space">{labelText} &#160;</span>
                <input onInput={e => onChangeValue(e.target.value)} ref={ref} id={id} name={id} readOnly={readOnly}
                       value={value===null ? "": value }
                       type="text"
                       {...rest}
                       data-hj-suppress=""/>
            </label>
        </div>
    );
}
