import React, {useState, useEffect} from 'react';
import './style.scss';
import {validateName, validatePhone} from "./recipientValidation";
import {cleanString} from "../../services/utils"
import Input from '../Input';

const Recipient = (props) => {
    const [recipient, setRecipient] = useState({recipient_name: '', recipient_phone: ''});
    const [nameErrorMessage, setNameErrorMessage] = useState(null);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState(null);
    const [nameIsValid, setNameIsValid] = useState(true);
    const [phoneIsValid, setPhoneIsValid] = useState(true);

    useEffect(() => {
        const _validatePhone = (_recipient) => {
            let inputValue =_recipient.recipient_phone;
            let isValid = validatePhone(inputValue);
            if (!isValid && inputValue) {
                setPhoneErrorMessage("Fyll i giltigt telefonnummer");
            }
            setPhoneIsValid(isValid);
            return isValid;
        };
        const _validateName = (_recipient) => {
            let isValid = validateName(_recipient.recipient_name);
            setNameIsValid(isValid);
            return isValid;
        };
        const validateAll = (recipient) => {
            let checkNameIsValid = _validateName(recipient);
            let checkPhoneIsValid = _validatePhone(recipient);
            return checkNameIsValid && checkPhoneIsValid;
        };

        setRecipient(props.recipient);
        let isValid = validateAll(props.recipient);

        let b = props.recipient;
        b.isValid = isValid;
        props.recipientChanged(b);
    }, [props]);

  

    const recipientNameChanged = (e) => {
        let val = cleanString(e.target.value);
        let isValid = validateName(val);
        setNameIsValid(isValid);
        let errorMess = !isValid ? "Leveransmottagare måste anges" : null;
        setNameErrorMessage(errorMess);
        let b = {...recipient};
        b.recipient_name = val;
        b.isValid = isValid;
        setRecipient(b);
        props.recipientChanged(b);
    };

    const recipientPhoneChanged = (e) => {
        let inputValue =e.target.value;
        let isValid = validatePhone(inputValue);
        setPhoneIsValid(isValid);
        let errorMess = !isValid ? "Ogiltigt telefonnummer" : null;
        setPhoneErrorMessage(errorMess);
        let b = {...recipient};
        b.recipient_phone = inputValue;
        b.isValid = isValid;
        setRecipient(b);
        props.recipientChanged(b);
    };

    return (
        <div id="billing-details" className="billing-details col-container">
            <div className="col two full_line">
                <Input errorMessage={nameErrorMessage} label="Leveransmottagare namn"
                    id="recipient-name" placeholder="Leveransmottagare namn" name="recipient-name"
                    type="text"
                    className={nameIsValid ? 'is-valid' : 'invalid'}
                    onChange={(e) => recipientNameChanged(e)}
                    value={recipient.recipient_name || ''}/>
            </div>
            <div className="col two full_line">
                <Input errorMessage={phoneErrorMessage} label="Leveransmottagare telefonnummer"
                    id="recipient-phone" placeholder="Leveransmottagare telefonnummer" name="recipient-phone"
                    type="text"
                    className={phoneIsValid ? 'is-valid' : 'invalid'}
                    onChange={(e) => recipientPhoneChanged(e)}
                    value={recipient.recipient_phone || ''}/>
            </div>

        </div>
    );
};
export default Recipient;
