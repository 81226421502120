export const validateName = (name) => {
    if (name && name.trim())
    {
        return true;
    }
    else {
        return false;
    };
}

export const validatePhone = (phone) => {
    return phone && !!phone.replace(/[ \-+]/gi, '').match('^[0-9 -+]{8,15}$');
}

