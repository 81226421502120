import React, {useState, useEffect} from 'react';
import postMessage from "../../services/postMessage";
import {pollCheckoutOrder} from "../../services/api.js";
import Button from "../../components/Button";
import logo from "../../assets/logo.svg";
import OnResize from "../checkout/resizeHandler";
import DefaultTestModeIndicator from '../../components/TestModeBannerDefault';
import Spinner from "../../components/Spinner";
import ErrorView from '../../components/ErrorView';
import {getIdFromQueryString} from '../../services/utils'

import './style.scss';
import RejectedBanner from '../../components/RejectedBanner';


const SigningCompleteScene = () => {
    const [confirmationUrl, setConfirmationUrl] = useState(null);
    const [orderReferences, setOrderReferences] = useState(null);
    const [showRejectedBanner, setShowRejectedBanner] = useState(false);
    const [customerOrganizationName, setCustomerOrganizationName] = useState("");
    const [testMode, setTestMode] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState("Vi behandlar ditt köp, var god vänta...");

    const _handleRedirect = () => {
        postMessage.checkoutRedirect(orderReferences);
    }

    const _handleCancel = () => {
        postMessage.checkoutCancel(orderReferences);
    }

    useEffect(() => {
        let id = getIdFromQueryString();
        if (!id) {
            console.log("missing querystring:id");
            setHasError(true);
        }
        const timer = window.setTimeout(() => {
            setLoadingText("Oj, det här tog lite längre än väntat. Var god stäng inte fönstret.")
        }, 10000);
        let retries = 4;
        const fetchCheckout = () => {
            return pollCheckoutOrder(id).then(res => {
                if (res.checkout_state.toLowerCase() === "rejected") {
                    setCustomerOrganizationName(res.customer_organization_name);
                    setIsLoading(false);
                    setShowRejectedBanner(true);
                } else {
                    setTestMode(res.test_mode);
                    let orderReferences = res.order_references;
                    setOrderReferences(orderReferences);
                    let returnUrl = res.checkout_completed_return_url;
                    if (returnUrl !== null && returnUrl.length > 0) {
                        setConfirmationUrl(returnUrl);
                    }
                    setIsLoading(false);
                    postMessage.checkoutComplete(orderReferences);
                    postMessage.scrollToTop();
                }
            }).catch(err => {
                console.log(`Status: ${err.statusCode}`);
                if (retries > 0 && err.statusCode && err.statusCode === 504) {
                    retries--;
                    fetchCheckout();
                    setLoadingText("Ditt köp hanteras fortfarande. Var god stäng inte fönstret.")
                } else {
                    setIsLoading(false);
                    console.log("Error fetching checkout", err);
                    setHasError(true);
                }
            }).finally(() => window.clearTimeout(timer));
        }
        fetchCheckout();
    }, [])

    let buttonLayout = confirmationUrl ?
        <div className="clearfix">
            <Button
                id="proceed-after-signing"
                onClick={_handleRedirect}
                className="signing-complete__content__whats-next__button"
                label={`Gå vidare`}/>
        </div> :
        null;


    return (hasError ? <ErrorView/> :
            isLoading ?
                <div className="signing-complete-spinner">
                    <div>
                        <h2>{loadingText}</h2>
                    </div>

                    <div>
                        <Spinner/>
                    </div>
                </div> :
                showRejectedBanner ?
                    <RejectedBanner companyName={customerOrganizationName} handleCancel={e => _handleCancel(e)}/> :
                    <div>
                        <OnResize/>
                        {
                            testMode &&
                            <DefaultTestModeIndicator/>
                        }
                        <div className="signing-complete-container">
                            <div className="signing-complete-header">
                                <img src={logo} alt="Wasa Kredit Logo"/>
                                <h1>
                                    Tack för ditt köp!
                                </h1>
                            </div>
                            <div>
                                <p id="thank-you-message">När dina varor skickats får du fakturan via e-post.</p>
                                {buttonLayout}
                            </div>
                        </div>
                    </div>
    );
}
export default SigningCompleteScene;