  
export const setCookie = (name, value) =>{
        let date = new Date();
        date.setDate(date.getDate() + 30); //add 30 days
        let expires = "expires=" + date.toGMTString();
        let cookie =  name +"="  + value +";"+ expires;
        document.cookie = cookie;
    };
export const getCookie = (name) =>{
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result =result[1]);
        return result;
}
