import React, { useState, useEffect } from 'react';
import {getTerms} from "../../../services/api.js";
import ErrorView from '../../../components/ErrorView';

import "./style.scss";

const TermsView = (props) => {
    const [hasError, setHasError] = useState(false);
    const [terms, setTerms] = useState({gdprTerms: null, terms: null, partnerTermsUrl: null});
    useEffect(() => {

        const id = props.match.params.id;
        getTerms(id).then(res => {
            setTerms({
                terms: res.terms,
                gdprTerms: res.gdpr_terms,
                partnerTermsUrl: res.partner_terms_url
            });
        }).catch(err => {
            console.log("Error fetching terms", err);
            setHasError(true);
        });
    }, [])

  return (
    hasError ? <ErrorView/>
    :
    <div  className="terms-window">
        <div dangerouslySetInnerHTML={{__html: terms[props.match.params.action]}}/>
    </div>
  );
}
export default TermsView;