import React from 'react';
import format from "../../services/format";
import AddressChangeDisclaimer from '../AddressChangeDisclaimer';
import AmountExceedsLimitDisclaimer from '../AmountExceedsLimitDisclaimer';

import './style.scss';

const FinancingInfo = (props) => {
    const formatCurrency = format.currencyToFixed(0);
    return (
        <div>
            <div className="financing-info-container">
                <div className="financing-info__text">
                    <h3 className="financing-info__header">Information om din faktura</h3>
                    <p>{`- Fakturan skickas till din E-post när leverans utförts.`}</p>
                    <p>{`- Fakturan betalas inom 30 dagar.`}</p>
                </div>
                {

                    props.customer!==undefined &&
                        <React.Fragment>
                {
                    props.signatoryCheckedForAdressChange &&
                    <AddressChangeDisclaimer customer={props.customer}/>
                }
                {
                    (!props.signatoryCheckedForAdressChange && !props.signatoryIsSelectable)  &&
                    <AmountExceedsLimitDisclaimer customer={props.customer}/>
                }


                        </React.Fragment>
                }
                

            </div>

            <div className="total-cost-container">
                <p className="total-cost-header">Totalt</p>
                <p className="large-paragraph">{formatCurrency(parseFloat(props.totalCost), "SEK")}</p>
                <p className="exvat_text"><small>Exkl. moms</small></p>
            </div>
        </div>
        );
    }

export default FinancingInfo;