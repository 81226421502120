export class Shout {
    static instance = undefined;
    subscribers = [];
    constructor() {
        if (!!Shout.instance) {
            return Shout.instance;
        }

        Shout.instance = this;
        return this;
    }
    subscribe = (message,callback) =>{
        let randId=this.generateId();
        this.subscribers.push({ message: message, callback: callback, id:randId });
        return randId;
    };
    shoutOut= (message, value) =>{
        this.subscribers.filter(s => s.message === message).forEach(
            s => s.callback(value)
        );
    }
    unsubscribe= (subscriberId) =>{
        this.subscribers.forEach((v,i)=>{
            if(v.id === subscriberId){
                this.subscribers.splice(i,1);
            }
        });
    }
    generateId =()=>{
        const rr=() =>{
            let low = 65;
            let high = 90
            return Math.floor((Math.random() * (high - low)) + low);
          }
        var arr = Array.from(Array(5).keys());
        return  arr.map(x=>String.fromCharCode(rr()).toLocaleLowerCase()).join("");
    }
}