import queryString from "query-string";

export const getIdFromQueryString = () => {
    return queryString.parse(window.location.search).id;
};
  
export const cleanPhoneString = (value) => {
    if (value)
    {
        return value.replace(/[^0-9 \-+]|^[^0-9+]|^(.+)\+|([ \-+])[ \-+]*/gi,'$1$2')
    }
    return "";
};

export const cleanString = (value) => {
    return value.replace(/^[^a-zåäö1-9]|(\s)\s*/gi,'$1');
};