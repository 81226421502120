import React, { useState } from 'react';
import './style.scss';
import { CleanIdentityNumber, isPersonNumberValid } from "../../services/validation";
import Input from "../Input";

const Signatory = (props) => {
    const [signatoryPersonNumber, setSignatoryPersonNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [pNumClassName, setpNumClassName] = useState("invalid");


    const changePersonalNumber = (pnr) => {
        if (pnr !== null) {
            pnr = pnr.trim();
            let result = isPersonNumberValid(pnr);
            setpNumClassName(result ? "is-valid" : "invalid")
            setSignatoryPersonNumber(pnr);
            if (!result) {
                setErrorMessage("Ange ett giltigt personnummer");
            } else {
                setErrorMessage(null);
            }
            props.signatoryPersonalNumberChanged(CleanIdentityNumber(pnr), result);
        }
    }

    return (
        <div className="signatory-container">
            <div id="signatoryPersonNumberContainer">
                <div id="agreement-signee">
                    <Input
                        id="personal-number"
                        className={pNumClassName}
                        label="Ditt personnummer"
                        placeholder="Ditt personnummer"
                        type="text"
                        errorMessage={errorMessage}
                        onChange={e => changePersonalNumber(e.target.value)}
                        value={signatoryPersonNumber} />

                    <p id="sign-disclaimer">Ditt personnummer används för att signera köpet via BankID.</p>

                </div>
            </div>
        </div>
    )
}

export default Signatory;