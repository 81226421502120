import 'whatwg-fetch';
import queryString from "query-string";
import { getMockResponse } from './mockedApi';
export const getUseMock = ()=>{
    return queryString.parse(window.location.search).useMock === "true";
}

const wrapApiCall =(apiFunc,mockMethodName)=> {
    return getUseMock() ? getMockResponse(mockMethodName) : apiFunc();
}

export function getDisclaimers() {
    return wrapApiCall(()=>fetchAuthorizedHandler(`/v1/invoiceCheckouts/disclaimers`),"getDisclaimers");
}

export const getCheckout = (checkoutId) => {
    return wrapApiCall(()=>fetchAuthorizedHandler(`/v1/invoiceCheckouts/${checkoutId}`),"getCheckout");
}

export const pollCheckoutOrder = (checkoutId) => {
    return wrapApiCall(()=>fetchAuthorizedHandler(`/v1/invoiceCheckouts/${checkoutId}/pollCheckoutOrder`),"pollCheckoutOrder");
}

export const getTerms = (partnerId) => {
    return wrapApiCall(()=>fetchAuthorizedHandler(`/v1/invoicePartners/${partnerId}/terms`),"getTerms");
}
export const getCheckoutStatus = (checkoutId) => {
    return wrapApiCall(()=>fetchAuthorizedHandler(`/v1/invoiceCheckouts/${checkoutId}/status`),"getCheckoutStatus");
}

export const putCustomer = (checkoutId, orgNo) => {
    return wrapApiCall(()=>putData(`/v1/invoiceCheckouts/${checkoutId}/customer/${orgNo}`),"putCustomer");
}

export const checkSignatory = (checkoutId, orgNo) => {
    return wrapApiCall(()=>putData(`/v1/invoiceCheckouts/${checkoutId}/checkSignatory/${orgNo}`),"checkSignatory");
}

export const checkSignatoryForAddressChange = (checkoutId, orgNo) => {
    return wrapApiCall(()=>putData(`/v1/invoiceCheckouts/${checkoutId}/checkSignatoryForAddressChange/${orgNo}`),"checkSignatoryForAddressChange");
}

export const clearSignatory = (checkoutId) => {
    return wrapApiCall(()=>putData(`/v1/invoiceCheckouts/${checkoutId}/clearSignatory`),"clearSignatory");
}


export const putInvoiceInformation = (checkoutId, request) => {
    return wrapApiCall(()=>putData(`/v1/invoiceCheckouts/${checkoutId}`, request),"putInvoiceInformation");
}

export const createBillOfPurchase = (checkoutId, request) => {
    return wrapApiCall(()=>putData(`/v1/invoiceCheckouts/${checkoutId}/sign`, request),"createBillOfPurchase");
}


const fetchAuthorizedHandler = (url, description) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            credentials: 'include',
            redirect: 'error',
        })
            .then(errorHandler)
            .then(response => {
                resolve(response.json());
            })
            .catch(err => {
                reject(err);
            })
    });
}


const putData = (url, data, description) => {
    return putOrPost(url, data, description, "PUT");
}

// const postData = (url, data, description) => {
//     return putOrPost(url, data, description, "POST");
// }

const putOrPost = (url, data, description, method) => {
    return new Promise((resolve, reject) => {

        fetch(url, {
            method: method,
            credentials: 'include',
            redirect: 'error',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(errorHandler)
            .then(response => {
                resolve(response.json());
            })
            .catch(err => {
                reject(err);
            });
    })
}

const errorHandler = (response) => {
    if (response.ok)
        return response;

    if (response.status >= 400 && response.status < 500) {
        return response.json()
            .then(json => {
                console.warn('Bad request : body', json);
                let error = Error(json.description);
                error.developer_message = json.developer_message;
                error.statusCode = response.status;
                throw error;
            })
            .catch(err => {
                console.warn('Bad request no json body:', err.message);
                throw err;
            });
    }

    return response.json()
        .then(json => {
            let error;
            if (json.developer_message) {
                console.error('Internal server error:', json.developer_message);
                error = Error(json.developer_message);
            } else {
                console.error('Internal server error:', json);
                error = Error(response.statusText);
            }
            error.statusCode = response.status;
            throw error;
        })
        .catch(err => {
            err.statusCode = response.status;
            console.log("Could not parse response to json!");
            throw err;
        });
}