import React, {useState, useEffect} from 'react';
import {CleanIdentityNumber, isOrganizationNumberValid} from "../../services/validation";
import {Shout} from '../../services/shout';
import Input from '../Input';
import emailValidator from "email-validator";

import "./style.scss";

const OrganizationNumberLookup = (props) => {
    const [orgNrIsValid, setOrgNrIsValid] = useState(false);
    const [organizationNumber, setOrganizationNumber] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState(null);
    const [orgNrErrorMessage, setOrgNrErrorMessage] = useState(null);
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState(null);
    const [purchaserEmail, setPurchaserEmail] = useState("");
    const [showValidationErrors, setShowValidationErrors] = useState(false);

    let shout = new Shout();

    useEffect(() => {
        emailChanged(props.purchaser_email);

        let setOrgNo = props.organizationNumber;
        if (setOrgNo === null) {
            setOrgNo = "";
        }

        validateOrgNumber(setOrgNo);
        let subscriberId = shout.subscribe("activateTestOrgnumber", (data) => {
            setOrganizationNumber(data.testOrgNumber);
            setOrgNrIsValid(true);
            setOrgNrErrorMessage(null);
        });
        return () => {
            shout.unsubscribe(subscriberId);
        }

    }, []);

    const emailChanged = (value) => {
        let isValid = emailValidator.validate(value);
        setEmailIsValid(isValid);
        !isValid
            ? setEmailErrorMessage("Ange en giltig e-postadress")
            : setEmailErrorMessage(null);

        setPurchaserEmail(value);
        props.purchaserEmailChanged(value, isValid)
    }

    const validateOrgNumber = (setOrgNo) => {
        const trimmedOrgNr = setOrgNo.trim();
        let result = isOrganizationNumberValid(trimmedOrgNr);
        setOrganizationNumber(setOrgNo);
        setOrgNrIsValid(result);
        const errorMessage = !result
            ? `Ange ett giltigt organisationsnummer`
            : null;
        setOrgNrErrorMessage(errorMessage);
        setLoadingCustomer(props.customerLoading);
    }

    const onSubmitOrgNo = (event) => {
        if (orgNrIsValid && emailIsValid) {
            props.onSubmitOrgNo(event, CleanIdentityNumber(organizationNumber))
        } else {
            setShowValidationErrors(true);
            event.preventDefault();
        }
    }

    return (
        <div id="organization-number-lookup-div">
            <h3>Dina uppgifter</h3>
            <form id="organization-number-form" onSubmit={(event) => onSubmitOrgNo(event)}>
                <div className="col-container  borderTopLeft">
                    <div className="col two full_line">
                        <Input label="Din e-post"
                               id="purchaserEmailInput" placeholder="E-post" name="purchaser-email"
                               type="text"
                               errorMessage={showValidationErrors ? emailErrorMessage : null}
                               className={!emailIsValid && showValidationErrors ? 'invalid' : 'is-valid'}
                               onChange={(e) => emailChanged(e.target.value)}
                               value={purchaserEmail || ''}/>
                    </div>
                    <div className="col two full_line">
                        <Input label="Organisationsnummer"
                               id="organizationNumberInput" placeholder="Organisationsnummer"
                               name="organizationNumberInput"
                               type="text"
                               errorMessage={showValidationErrors ? orgNrErrorMessage : null}
                               className={!orgNrIsValid && showValidationErrors ? 'invalid' : 'is-valid'}
                               onChange={(e) => validateOrgNumber(e.target.value)}
                               value={organizationNumber || ''}/>
                    </div>
                </div>
                <button
                    type="submit"
                    id="organization-number-button"
                    disabled={loadingCustomer}
                    className={loadingCustomer ? "button-loading-state" : ""}>
                    Fortsätt
                </button>
            </form>

        </div>
    )
};
export default OrganizationNumberLookup;



