import React, {useState} from 'react';
import { isPostalCode } from '../../services/validation';
import Input from '../Input';
const AddressItem = (props) => {

    const [isPostalCodeValid, setIsPostalCodeValid] = useState(true)

    const checkPostalCode=(val)=>{
        let valid= isPostalCode(val);
        setIsPostalCodeValid(valid);
        return valid;
      }
  
    let _readOnly = !props.addressIsInEditMode;
    let _inputClassName = props.addressIsInEditMode ? "edit-mode": "disabled"; 
    return (
        <div className="address-item col-container">
          <div className="col two full_line">
            <Input
              className={_inputClassName} 
              label="Adress" 
              onChange={(e)=>props.deliveryAddressStreetAddressChanged(e,"street_address")}
              value={props.address.street_address}
              readOnly={_readOnly}/>
          </div>
          <div className="col two">
            <div className="col-container">
              <div className="col three full_line">
                <Input
                  className={_inputClassName + (!isPostalCodeValid? " invalid" :"") }  
                  label="Postnummer" 
                  onChange={(e)=>props.deliveryAddressStreetAddressChanged(e,"postal_code",()=> checkPostalCode(e.target.value))}
                  value={props.address.postal_code}
                  readOnly={_readOnly}/>
              </div>
              <div className="col three full_line">
                <Input
                  className={_inputClassName}  
                  label="Ort" 
                  onChange={(e)=>props.deliveryAddressStreetAddressChanged(e,"city")}
                  value={props.address.city}
                  readOnly={_readOnly}/>
              </div>
            </div>
          </div>
      </div>

        );
    }
export default AddressItem;
