export const isOrganizationNumberValid = (input) => {
    return !!(CheckFormat(input) && CheckLuhnAlgorithm(CleanIdentityNumber(input)));
}
export const isPostalCode = (val) => {
    if (val === null) {
        return false;
    }
    val = val.trim();

    return val.match(/^[0-9]{5}$/) !== null;
}
export const isPersonNumberValid = (input) => {
    if (!input || !input.match(/^(\d{2}|\d{4})(\d{2})(\d{2})-?(\d{4})$/)) {
        return false;
    }

    // Get year, month and day
    const year = RegExp.$1,
        month = RegExp.$2 - 1,
        day = RegExp.$3;
    // Check that year month and day are possible numbers
    if (month < 0 || month > 12 || day < 1 || day > 31) {
        return false;
    }

    // Check valid date
    // FIXME: Check for potential edge-cases with 2-digit years(new date will interpret these as 19xx)
    let d = new Date(year, month, day)
    if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) {
        return false;
    }

    return CheckLuhnAlgorithm(CleanIdentityNumber(input));
}

export function CleanIdentityNumber(input) {
    // Clean input from - (dash)
    input = input.replace("-", "");

    // Trim length if in format YYYYMMDDXXXX => YYMMDDXXXX
    if (input.length === 12) {
        input = input.substring(2);
    }
    return input;
}

function CheckFormat(input) {
    // Check input is defined and could be orgnr/personalnumber
    if (!input) {
        return false;
    }

    return input.match(/^(\d{6}|\d{8})-?\d{4}$/);
}

function CheckLuhnAlgorithm(input) {
    let sum = 0,
        numdigits = input.length,
        parity = numdigits % 2,
        i,
        digit;

    for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(input.charAt(i), 10);
        if (i % 2 === parity) {
            digit *= 2;
        }
        if (digit > 9) {
            digit -= 9;
        }
        sum += digit;
    }
    return sum % 10 === 0;
}
