import React from 'react';
import './style.scss';
import Input from "../Input";

const NonSelectableSignatory = () => {
    return (
        <div className="signatory-container">
            <div id="signatoryPersonNumberContainer">
                <div id="agreement-signee">
                            <Input
                            id="personal-number"
                            disabled={true}
                            className="disabled"
                            label="Ditt personnummer"
                            placeholder=""
                            type="text"
                            value=" "
                            
                        />
                </div>
            </div>
        </div>
    )
}

export default NonSelectableSignatory;