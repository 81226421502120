import React from 'react';
import DisclaimerTextTemplate from '../AddressChangeDisclaimer/DisclaimerTextTemplate.jsx';
import './style.scss';

const AmountExceedsLimitDisclaimer = (props) => {
    const getDisclaimerText =  ()=>{
        let legalForm = props.customer?.company_legal_form;
        return `Obs! Köpet kräver signatur av ${(legalForm || "").toLowerCase() === "ab" ? "VD" : "innehavare av enskild firma"}.`;
    }
    return (
        <DisclaimerTextTemplate>
        {
            getDisclaimerText()
        }
        </DisclaimerTextTemplate>

    )
}

export default AmountExceedsLimitDisclaimer;