import React from 'react';
import "../bannerStyle.scss";
import "./style.scss";

const TestModeBanner = (props) => {    
    const handleClick=()=>{
        props.onSetTestModeOrgNo();
    }
    return (
            <div id="testmode-container" className="banner_container">
                <h4 className="header">Testmode is enabled</h4>
                <div className="content"> 
                    <h5 className="sub-title">
                        Test company
                    </h5>
                    <p className="info-text">
                        To complete the checkout, use our test company with organisation number 6806249022.
                    </p>
                    <button
                        onClick={()=>handleClick()}
                        id="checkout-testmode-autofill-organization-number"
                        >
                     Use test company
                         
                    </button>
                    <div className="clear-both"></div>
                </div>
            </div>
    )
};
export default TestModeBanner;



