import postMessage  from "../../services/postMessage";
import React from 'react';
import ReactResizeDetector from "react-resize-detector";
const OnResize = ()=> {
  function updateSize() {
    let d = document;
    let documentElement = d.documentElement;
    let body = d.getElementsByTagName("body")[0];
    let height = (body.clientHeight || documentElement.clientHeight) + 50;
    postMessage.resize(height);
  }

    return (
        <React.Fragment>
 <ReactResizeDetector
            handleWidth={true}
            handleHeight={true}
            onResize={updateSize.bind(this)}/>

        </React.Fragment>
    );
  }
export default OnResize;
 

