import React from 'react';
import './style.scss';

const DisclaimerTextTemplate = (props) => {
    return (
        <div className="change-address-disclaimer-container">
            <div id="sign-disclaimer">
            <p>
            {
                props.children
            }
            </p>

            </div>
        </div>
    )
}

export default DisclaimerTextTemplate;