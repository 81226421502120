import React from 'react';
import "../bannerStyle.scss";
import "./styles.scss";
import ButtonWithLeftArrow from "../../components/ButtonWithLeftArrow";
import redCornerIcon from  "../../assets/red-corner.svg";

const BannerBase = (props) => {
    const changePayment=(e)=>{
        e.preventDefault();
        props.handleCancel();
    }

    return (

        <div id="rejected-banner" className="banner_container">
            <img alt="red corner" className="red-corner" src={redCornerIcon} />
            <h4 id="banner-header" className="header">{props.header}</h4>
            <div className="content">
                <p id="banner-text" className="info-text">
                    {props.children}
                </p>
                <div>
                    <ButtonWithLeftArrow
                        id="change-payment-method"
                        onClick={e => changePayment(e)}
                        className="change-payment-method__button"
                        label={`Byt betalsätt`} />
                </div>

                <div className="clear-both">
                </div>
            </div>
        </div>
    )
};
export default BannerBase;



