import React,{useState, useEffect} from 'react';
import './style.scss';
import Input from '../Input';

const BillingDetails = (props) => {
    const [billingDetails, setBillingDetails] = useState({billing_reference:'',billing_tag:'', purchaser_email:''});

    useEffect(()=>{
        var b = props.billingDetails;
        props.billingDetailsChanged(b);        
        setBillingDetails({...props.billingDetails});
    },[])


    const billingReferenceChanged = (e, fieldName)=>{
        let val= e.target.value;
        let b = {...billingDetails};
        b[fieldName] = val;
        setBillingDetails(b);
        props.billingDetailsChanged(b);
    }

    return (
        <div id="billing-details" className="billing-details">
            <div className="col-container">
                <div className="col two full_line">
                    <Input label="Fakturareferens (Valfritt)"
                                        placeholder="Fakturareferens (Valfritt)"
                                        id="billing-reference"  
                                        name="billing-reference"
                                        type="text"
                                        onChange={(e)=>billingReferenceChanged(e, "billing_reference")}
                                        value={billingDetails.billing_reference || ''}/>
                </div>
                <div className="col two full_line">
                    <Input label="Märkning (Valfritt)"
                                        placeholder="Märkning (Valfritt)"
                                        id="billing-tag" name="billing-tag"
                                        type="text"
                                        onChange={(e)=>billingReferenceChanged(e, "billing_tag")}
                                        value={billingDetails.billing_tag || ''}/>
                </div>    
            </div>

            <div className="col-container">
                <div className="col two full_line">
                <Input label="Ditt namn (Valfritt)"
                                placeholder="Ditt namn (Valfritt)"
                                id="purchaser-name"  name="purchaser-name"
                                type="text"
                                onChange={(e)=>billingReferenceChanged(e, "purchaser_name")}
                                value={billingDetails.purchaser_name || ''}/>
                </div>
                <div className="col two full_line">
                    {
						props.children
					}
                </div>
            </div>
            
            
        </div>
      );
    }
export default BillingDetails;
