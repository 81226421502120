import React, {Component} from "react";
import redCornerIcon from "../../assets/red-corner.svg";
import "./style.scss";
import * as api from "../../services/api";

export default class DisclaimerInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: false,
            disclaimers: []
        };
    }

    componentDidMount() {
        api.getDisclaimers()
            .then(response => {
                const body = response;
                if (body.disclaimers.length > 0) {
                    this.setState({disclaimers: body.disclaimers});
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {
        if (this.state.disclaimers.length === 0) {
            return null;
        }
        return (
            <div>{
                this.state.disclaimers.map((d, i) => {
                    return (
                        <div id="test-mode-indicator" key={"disclaimer_" + i} className="wrapper">
                            <div className="container">
                                <img className="red-corner" src={redCornerIcon} alt=""/>
                                <h4 className="header">{d.header}</h4>
                                {d.text}
                                <br/><br/>
                                {
                                    (d.url != null && d.url.length > 0) &&
                                    <a href={d.url}>{(d.link_label != null && d.link_label.length > 0) ? d.link_label : d.url}</a>
                                }
                            </div>
                        </div>)

                })
            }
            </div>
        );
    }
}

