import React, {useState, useEffect } from 'react';
import emailValidator from "email-validator";
import '../../columns.scss';
import './style.scss';
// import AddressItem from './AddressItem';
import BillingDetails from './BillingDetails';
import Recipient from './Recipient';
import Input from '../Input';
import {checkSignatoryForAddressChange, clearSignatory} from '../../services/api';
import ChangeAddressButton from './ChangeAddressButton';
import AddressItem from './AddressItem';


const CustomerAddress = (props) => {
    const [customer,setCustomer] = useState(null);
    const [deliveryAddresses, setDeliveryAddresses] = useState(null);
    const [originalDeliveryAddresses, setOriginalDeliveryAddresses] = useState(null);
    const [infoBoxTimer, setInfoBoxTimer] = useState(null);
    const [addressIsInEditMode, setAddressIsInEditMode] = useState(false);
    const [origSignatoryIsSelectable, setOrigSignatoryIsSelectable] = useState(false);
    const [originalEmail, setOriginalEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [showNotAllowedToChangeAdressInfo,setShowNotAllowedToChangeAdressInfo] =useState(false);
    
    Array.prototype.selectItem = function(id) {
      if(id === null){return;}
      this.forEach(x=>x.selected = false); //clear all
      this.filter(x=>x.id === id)[0].selected=true;
    }
    
    useEffect(()=>{
        if(props.customer == null){
          return;
        }
        const cloneAddress= (addresses)=>{
          return addresses.map(a => (
            {...a}
            ));
        }

        setCustomer(props.customer);

        let deliveryAddresses = cloneAddress([...props.customer.addresses]);
        setDeliveryAddresses(deliveryAddresses);   

        let origDeliveryAddresses = cloneAddress([...props.customer.addresses]);
        setOriginalDeliveryAddresses(origDeliveryAddresses);
        
        setOriginalEmail(props.billingDetails.purchaser_email);

    },[props.customer])

    useEffect(()=>{

    },[props.billingDetails]);

    useEffect(()=>{
      setOrigSignatoryIsSelectable(props.signatoryIsSelectable); //needed for UNDO function

    },[])


    const onChangeAddressButtonClick = (evt)=>{
      evt.preventDefault();
      checkSignatoryForAddressChange(props.checkoutModel.checkout_id, props.customer.organization_number).then(res=>{
        if(res.is_invoice_address_adjustable === true){
          setAddressIsInEditMode(true);
          props.setSignatoryIsSelectable(false);
          props.setSignatoryCheckedForAdressChange(true);
        }
        else{
          clearTimer();
          setShowNotAllowedToChangeAdressInfo(true);
          var timer = window.setTimeout(()=>{
            setShowNotAllowedToChangeAdressInfo(false);
          }, 5000);
          setInfoBoxTimer(timer);
        }
        
      }).catch(err=>{
        console.log("fel vid checkSignatoryForAdressChange");
      })
    }

    const clearTimer = () =>{
      if(infoBoxTimer!==null){
        window.clearTimeout(infoBoxTimer);
      }

    }


    const deliveryAddressStreetAddressChanged = (e, field,validationFunc) => {
      let inputValue =e.target.value;
      let b = [...deliveryAddresses];
      b[0][field] = inputValue;

      setDeliveryAddresses(b);

      if(validationFunc && !validationFunc()){
        props.setDeliveryAddressIsValid(false);
        return;
      }
      props.setDeliveryAddressIsValid(true);
      props.deliveryAddressChanged(b[0]); //we only have one address as of now
    };

    const purchaserEmailChanged = (e) =>{
      var val = e.target.value;
      //validate email address
      let emailIsValid = emailValidator.validate(val);
      setIsEmailValid(emailIsValid);
      props.setDeliveryAddressIsValid(emailIsValid);

      var _billingDetails = {...props.billingDetails};
      _billingDetails["purchaser_email"] = val;
      props.billingDetailsChanged(_billingDetails);
    }

    const onRedoAddressChangeClick = ()=>{
      const resetAddress = ()=>{
        let d = [...deliveryAddresses];
        d[0].street_address = originalDeliveryAddresses[0].street_address;
        d[0].postal_code = originalDeliveryAddresses[0].postal_code;
        d[0].city = originalDeliveryAddresses[0].city;
        
        var _billingDetails = {...props.billingDetails};
        _billingDetails["purchaser_email"] = originalEmail;
        props.billingDetailsChanged(_billingDetails);
        props.deliveryAddressChanged(null); //sets the values sent on submit (ie null = leave address unchanged)

        setAddressIsInEditMode(false);
        props.setSignatoryCheckedForAdressChange(false);
        props.setSignatoryIsSelectable(origSignatoryIsSelectable);

        props.setDeliveryAddressIsValid(true);
        setIsEmailValid(true);
      }
      
      if(props.setSignatoryIsSelectable){
        clearSignatory(props.checkoutModel.checkout_id ).then(res=>{
          resetAddress();
        }).catch(err=>{
          console.log("fel vid ångrande av adressändring")
        });

      }
      else{
        resetAddress();
      }
    }

  

    if(customer===null || deliveryAddresses ===null){
      return (
        <React.Fragment/>
      )
    }

    return (

        <div id="customer-address-container">
          <h2>{deliveryAddresses[0].company_name}</h2>
          <div className="delivery-address-container">
            <h4>Leveransadress</h4>
            <ChangeAddressButton 
              addressIsInEditMode={addressIsInEditMode}
              companyLegalForm = {props.customer.company_legal_form}
              onChangeAddressButtonClick={onChangeAddressButtonClick}
              onRedoAddressChangeClick = {onRedoAddressChangeClick}
              /> 

            {showNotAllowedToChangeAdressInfo && 
              <div className="info-box">Det är inte möjligt att ändra leveransadress</div>
            
            }
          </div>
          <div className={"edit-mode-address-container " + (addressIsInEditMode? "editmode editmode2":"") }>
            <div className="col-container">
              <div className="col two full_line">
                <Input className={(addressIsInEditMode ? "edit-mode": "disabled") + (!isEmailValid? " invalid" :"")} onChange={purchaserEmailChanged} readOnly={!addressIsInEditMode}  label="E-post" value={props.billingDetails.purchaser_email}/>
              </div>
              <div className="col two full_line">
                <Input className="disabled" readOnly={true}  label="Organisationsnummer" value={props.customer.organization_number}/>
              </div>
          </div>
          <div>
            {
              <AddressItem
                address={deliveryAddresses[0]}
               addressIsInEditMode={addressIsInEditMode}
               deliveryAddressStreetAddressChanged={deliveryAddressStreetAddressChanged}
               />
            }
          </div>
          </div>
          <Recipient recipient={props.recipient} recipientChanged = {props.recipientChanged}/>
          <BillingDetails
              billingDetails = {props.billingDetails}
              billingDetailsChanged = {props.billingDetailsChanged}
              children = {props.children}
            />
        </div>
      );
    }
export default CustomerAddress;