import React from 'react';
import DisclaimerTextTemplate from './DisclaimerTextTemplate';
import './style.scss';

const AddressChangeDisclaimer = (props) => {
    const getDisclaimerText =  ()=>{
        let legalForm = props.customer?.company_legal_form;
        return `Obs! Vid ändring av adress krävs signatur av ${(legalForm || "").toLowerCase() === "ab" ? "VD" : "innehavare av enskild firma"}.`;
    }
    return (
        <DisclaimerTextTemplate>
            {
                getDisclaimerText()
            }
        </DisclaimerTextTemplate>
    )
}

export default AddressChangeDisclaimer;