import React from "react";
import "./styles.scss";
import arrowIcon from "../../assets/arrow_standard_left.svg";

type Props = {
	label: any,
	className?: string,
	id?: string,
};

export default function ButtonWithLeftArrow(props: Props) {
	let { label, className, id, ...rest } = props;
	let classNames = className ? ["button-left-arrow", className].join(" ") : "button";
	return (
		<button {...rest} id={id} className={classNames}>
			<img src={arrowIcon} alt="Left arrow"></img>
			{label}
		</button>
	);
}
