import React from 'react'
import './style.scss';
import enhorning from "./../../assets/enhorning.png"
import DisclaimerInfo from "../DisclaimerInfo";

const ErrorView = () => {
    return (
        <div id="errorView" className="error-container">
            <DisclaimerInfo/>
            <div className="info-container">
                <img className="unicorn" alt="Enhörning" src={enhorning}/>
                <h1>
                    <span className="status-code">{"Oj!"}</span> {"Nu gick något fel!"}
                </h1>
                <h2>
                    {"Var god ladda om sidan och försök igen. "}
                </h2>
                <span className='contact-us'>Kontakta oss: <a href='mailto:ehandel@wasakredit.se'>ehandel@wasakredit.se</a></span>
            </div>
        </div>
    )
}
export default ErrorView
  