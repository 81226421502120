import React from 'react';
import "../bannerStyle.scss";


const TestModeBannerDefault = () => {
    return (
            <div id="testmode-container" className="banner_container">
                <h4 className="header">Testmode is enabled</h4>
            </div>
    )
};
export default TestModeBannerDefault;



