import React from 'react';
import "./styles.scss";
import BannerBase from "../BannerBase";

const RejectedBanner = (props) => {
    return (
            <BannerBase header = {'Tyvärr kan vi inte erbjuda ert företag det här betalalternativet'} handleCancel = {e => props.handleCancel(e)} >
                <br/> Vänligen välj ett annat betalalternativ.
            </BannerBase>
    )
};
export default RejectedBanner;



