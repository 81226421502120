import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import './style.scss';
import '../../columns.scss';
import {getTerms} from "../../services/api.js";

import ErrorView from '../../components/ErrorView';

import WasaLogo from '../../assets/logo.svg'
import bankIdLogo from '../../assets/bankid_vector_rgb.svg'

const TermsScene = (props) => {
    const [hasError, setHasError] = useState(false);
    const [terms, setTerms] = useState({gdprTerms: null, terms: null, partnerTermsUrl: null});

    useEffect(() => {
        if (props.checkoutModel === null) {
            return;
        }
        getTerms(props.checkoutModel.partner_id).then(res => {
            setTerms({
                partnerTermsUrl: res.partner_terms_url
            });
        }).catch(err => {
            console.log("Error fetching terms", err);
            setHasError(true);
        });
    }, [props.checkoutModel])


    return (
        hasError ? <ErrorView/> :
            (props.checkoutModel == null) ?
                <div/> :
                <div className="terms-container">
                    <div id="wasa-kredit-terms" className="partner-terms">
                        <span>Genom att slutföra köpet godkänner jag </span>
                        <NavLink to={`/terms/${props.checkoutModel.partner_id}/terms`} target="_blank">
                            <nobr>allmänna villkor</nobr>
                        </NavLink>
                        ,&#160;
                        <a id="cookie-policy" href='http://www.wasakredit.se/om-wasa-kredit/cookies' target="_blank">cookiepolicy</a>
                        <span> och hantering av </span>
                        <NavLink to={`/terms/${props.checkoutModel.partner_id}/gdprTerms`} target="_blank">personuppgifter</NavLink>
                        <span>.</span>
                        <span className='contact-us'> Kontakta oss: <a href='mailto:ehandel@wasakredit.se'>ehandel@wasakredit.se</a></span>
                    </div>
                    {
                        (terms.partnerTermsUrl !== null && terms.partnerTermsUrl.length > 0) &&
                        <div id="partner-terms" className="partner-terms">
                            <span>Samt </span>
                            <a href={terms.partnerTermsUrl} rel="noopener noreferrer" target="_blank"> säljarens
                                villkor</a>
                        </div>
                    }
                    <div className="logo-container">
                        <img src={bankIdLogo} height="100" id="bankIdLogo" alt="BankId Logo"/>
                        <img src={WasaLogo} height="70" id="wasalogo" alt="Wasa Kredit Logo"/>
                    </div>
                </div>
    );
}
export default TermsScene;