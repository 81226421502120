const currencyFormat = currency => {
    switch(currency.toUpperCase()) {
      case "EUR":
        return { prefix: "€", suffix: "" };
      case "GBP":
        return { prefix: "£", suffix: "" };
      case "SEK":
        return { prefix: "", suffix: " kr" };
      case "USD":
        return { prefix: "$", suffix: "" };
      default:
        return { prefix: "", suffix: "" };
    }
  };
  
  const round = number => {
    var factor = Math.pow(10, 0);
    return Math.round(number * factor) / factor;
  };
  
  const currencyAux = (amountTransformer) => {
    return (amount, currency) => {
      const parsedAmount = (amountTransformer !== undefined ? amountTransformer(amount) : amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      const c = currencyFormat(currency);
      return `${c.prefix}${parsedAmount}${c.suffix}`;
    }
  };
  export default {
    currency: currencyAux(round),
    currencyNoRound: currencyAux(),
    currencyToFixed: (fractionDigits) => currencyAux(val => typeof val == 'number' ? val.toFixed(fractionDigits) : val),
  }
  